<template>
    <v-card class="mt-2 ml-2 mr-2">
        <FJenisKenaikanPangkatTable class="pl-2 pr-2" ref="refJenisKenaikanPangkatTable"/>
    </v-card>
  </template>
  
  <script>
  import FJenisKenaikanPangkatTable from "@/components/admin_setup/jenis_kenaikan_pangkat/FJenisKenaikanPangkatTable";
  
  export default {
    name: "JenisKenaikanPangkat",
    components: {
      FJenisKenaikanPangkatTable
    },
    data() {
      return {
        tabTitle: 'JenisKenaikanPangkat'
      }
    },
    computed: {
    },
    methods: {
      tabJenisKenaikanPangkatClick(){
        this.$refs.refJenisKenaikanPangkatTable.fetchParent()
      },
  
    },
  
  
  }
  </script>
  
  <style scoped>
  </style>